import { FrontmatterBlock } from '@myst-theme/frontmatter'
import { Theme, ThemeProvider } from '@myst-theme/providers'
import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import type { FileDto } from '@viastud/server/services/file_service'
import { AlertTriangleIcon } from 'lucide-react'
import type { References } from 'myst-common'
import { DEFAULT_RENDERERS, MyST } from 'myst-to-react'
import { useEffect, useState } from 'react'
import { VFile } from 'vfile'

import { AdmonitionRenderer, AdmonitionTitle } from '#components/myst/admonitions'
import { Image } from '#components/myst/image'
import { ProofRenderer } from '#components/myst/proof'
import { cn } from '#lib/utils'

const RENDERERS = {
  ...DEFAULT_RENDERERS,
  admonition: AdmonitionRenderer,
  admonitionTitle: AdmonitionTitle,
  image: Image,
  proof: ProofRenderer,
}

async function parse(text: string) {
  const { unified } = await import('unified')
  const {
    basicTransformationsPlugin,
    enumerateTargetsPlugin,
    getFrontmatter,
    keysPlugin,
    mathPlugin,
    reconstructHtmlPlugin,
    ReferenceState,
    resolveReferencesPlugin,
  } = await import('myst-transforms')
  const { proofDirective } = await import('myst-ext-proof')
  const { mystParse } = await import('myst-parser')

  const vfile = new VFile()
  const parseMyst = (content: string) =>
    mystParse(content, {
      markdownit: { linkify: true },
      directives: [proofDirective],
      vfile,
    })
  const mdast = parseMyst(text)
  const references = {
    cite: { order: [], data: {} },
    footnotes: {},
  }
  const frontmatter = getFrontmatter(vfile, mdast, {
    keepTitleNode: true,
  }).frontmatter

  const state = new ReferenceState('', {
    numbering: frontmatter.numbering,
    vfile,
  })

  unified()
    .use(reconstructHtmlPlugin)
    .use(basicTransformationsPlugin, { parser: parseMyst })
    .use(mathPlugin, { macros: frontmatter.math })
    .use(enumerateTargetsPlugin, { state })
    .use(resolveReferencesPlugin, { state })
    .use(keysPlugin)
    .runSync(mdast, vfile)

  return {
    frontmatter,
    references: { ...references, article: mdast } as References,
    warnings: vfile.messages,
  }
}

interface MystProps extends React.ComponentProps<'div'> {
  text: string
  displayError?: boolean
  withHeader?: boolean
  images: FileDto[]
}

function replaceImages(content: string, images: FileDto[]) {
  let newContent = content
  for (const image of images) {
    newContent = content.replace(image.name, image.id)
  }
  return newContent
}

const MystRenderer = ({ ref, text, images, withHeader, displayError = false }: MystProps) => {
  const [parsedText, setParsedText] = useState<Awaited<ReturnType<typeof parse>>>()

  useEffect(() => {
    async function updateParsedText(text: string, images: FileDto[]) {
      const newParsedText = await parse(replaceImages(text, images))
      setParsedText(newParsedText)
    }

    void updateParsedText(text, images)
  }, [text, images])

  return (
    <>
      <div className="article hidden px-4">
        {parsedText && (
          <ThemeProvider renderers={RENDERERS} theme={Theme.light} setTheme={() => {}}>
            {parsedText.warnings.length > 0 && displayError && (
              <div className="mb-4 flex w-full gap-4">
                {parsedText.warnings.map((m) => (
                  <div
                    key={m.line ?? m.name}
                    className={cn('not-prose w-full p-1 text-white shadow-inner', {
                      'bg-red-500 dark:bg-red-800': m.fatal === true,
                      'bg-orange-500 dark:bg-orange-700': m.fatal === false,
                      'bg-slate-500 dark:bg-slate-800': m.fatal === null,
                    })}
                  >
                    {m.fatal === true && (
                      <AlertTriangleIcon width="1.3rem" height="1.3rem" className="mr-1 inline" />
                    )}
                    {m.fatal === false && (
                      <ExclamationTriangleIcon
                        width="1.3rem"
                        height="1.3rem"
                        className="mr-1 inline"
                      />
                    )}
                    {m.fatal === null && (
                      <AlertTriangleIcon width="1.3rem" height="1.3rem" className="mr-1 inline" />
                    )}
                    <code>{m.ruleId ?? m.source}</code>: {m.message}
                  </div>
                ))}
              </div>
            )}
            {withHeader && <FrontmatterBlock frontmatter={parsedText.frontmatter} />}
            <MyST ast={parsedText.references.article?.children} />
          </ThemeProvider>
        )}
      </div>
      <div className="article download z-10 max-w-4xl px-4" ref={ref}>
        {parsedText && (
          <ThemeProvider renderers={RENDERERS} theme={Theme.light} setTheme={() => {}}>
            <FrontmatterBlock frontmatter={parsedText.frontmatter} />
            <MyST ast={parsedText.references.article?.children} />
          </ThemeProvider>
        )}
      </div>
    </>
  )
}

MystRenderer.displayName = 'Myst'

export { MystRenderer as Myst }

import { z } from 'zod';
export const editUserProfileSchema = z.object({
    id: z.string().uuid(),
    lastName: z
        .string({
        required_error: 'Le nom est requis',
    })
        .min(1),
    firstName: z
        .string({
        required_error: 'Le prénom est requis',
    })
        .min(1),
    email: z
        .string({
        required_error: "L'adresse e-mail est requise",
    })
        .email()
        .min(1),
    phoneNumber: z
        .string({
        required_error: 'Le numéro de téléphone est requis',
    })
        .min(1),
});

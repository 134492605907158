import { createFileRoute } from '@tanstack/react-router'
import { trpc } from '@viastud/ui/lib/trpc'
import { ForgottenPassword } from '@viastud/ui/shared/forgotten-password'

export const Route = createFileRoute('/forgotten-password')({
  component: RouteComponent,
})

function RouteComponent() {
  return <ForgottenPassword sendResetLink={trpc.professorAuth.sendResetPasswordMail.useMutation} />
}

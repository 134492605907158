import type { Grade, Level, SubjectEnum } from '@viastud/utils'
import { GradeEnum, LevelEnum, SubjectEnumShort } from '@viastud/utils'
import { BookOpen, ChartNoAxesColumnIncreasing, Clock } from 'lucide-react'
import type React from 'react'

import { displayFormattedHour, displayFormattedMonth } from '#lib/reservations.utils'

interface SlotCardProps {
  date: {
    month: string
    dayOfMonth: number
    hour: number
    fullDate: string | null
  }
  courseName: string
  courseSubject: keyof typeof SubjectEnum
  courseGrade: Grade
  courseLevel: Level
  isEmpty?: boolean
  children?: React.ReactNode
}

export const SlotCard = ({
  date,
  courseName,
  courseGrade,
  courseLevel,
  courseSubject,
  children,
}: SlotCardProps) => {
  return (
    <div className="flex min-w-[325px] flex-col items-start justify-between rounded-xl border border-gray-200 bg-white p-4 shadow-sm">
      <div className="flex w-full gap-4">
        <div className="flex flex-col items-center justify-center rounded-lg bg-gray-100 p-4">
          <p className="text-sm font-semibold text-gray-600">{date.month}</p>
          <p className="text-2xl font-bold text-gray-900">
            {displayFormattedMonth(date.dayOfMonth)}
          </p>
          <p className="text-sm font-medium text-gray-600">{displayFormattedHour(date.hour)}</p>
        </div>

        <div className="flex flex-col gap-4">
          <p className="text-base font-bold text-gray-900">{courseName}</p>
          <div className="mt-2 flex flex-row gap-2 text-sm text-gray-600">
            <div className="flex flex-col items-start justify-between gap-1">
              <div className="flex items-center gap-1">
                <BookOpen size={15} /> {SubjectEnumShort[courseSubject]}
              </div>
              <div className="flex items-center gap-1">
                <ChartNoAxesColumnIncreasing size="15" />
                {LevelEnum[courseLevel]}
              </div>
            </div>

            <div className="flex flex-col items-start justify-between gap-1">
              <div className="flex items-center gap-1">
                <ChartNoAxesColumnIncreasing size="15" /> {GradeEnum[courseGrade]}
              </div>
              <div className="flex items-center gap-1">
                <Clock size={15} />
                <p>1&nbsp;heure</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 w-full">{children}</div>
    </div>
  )
}

import dayjs from 'dayjs'
import { z } from 'zod'

export const availabilityFormSchema = (daysOfWeekLength: number, hoursLength: number) =>
  z.object({
    availabilities: z.array(z.array(z.boolean()).length(daysOfWeekLength)).length(hoursLength),
  })

export const daysOfWeek = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche']
export const hours = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]

export const weekOptions = Array.from({ length: 4 }, (_, i) =>
  dayjs().add(i, 'week').startOf('week').add(1, 'day').toDate()
)

export const formattedWeekOptions = weekOptions.map((date) => ({
  label: `Semaine du ${dayjs(date).format('DD/MM/YYYY')}`,
  value: dayjs(date).format('YYYY-MM-DD'),
}))

import { createFileRoute } from '@tanstack/react-router'
import { Button } from '@viastud/ui/button'
import { isCourseNowFromDateString } from '@viastud/ui/lib/reservations.utils'
import { trpc } from '@viastud/ui/lib/trpc'
import { cn } from '@viastud/ui/lib/utils'
import { SlotCard } from '@viastud/ui/shared/slot-card'

export const Route = createFileRoute('/_auth/')({
  component: Dashboard,
})

function Dashboard() {
  const { data: incomingCourses } = trpc.reservations.getProfessorSlots.useQuery({
    isAfterNow: true,
  })

  const { data: previousCourses } = trpc.reservations.getProfessorSlots.useQuery({
    isAfterNow: false,
  })

  return (
    <div className="w-[80%]">
      <div className="flex flex-row items-center justify-between gap-4 rounded-2xl p-6">
        <div className="text-2xl font-bold">Prochaines séances</div>
      </div>
      <div className="no-scrollbar mt-4 flex w-full flex-row gap-6 overflow-x-auto">
        {incomingCourses?.map((course) => (
          <SlotCard
            key={course.id}
            date={course.date}
            courseName={course.courseName}
            courseSubject={course.courseSubject}
            courseGrade={course.courseGrade}
            courseLevel={course.courseLevel}
          >
            {course.isEmpty ? (
              <Button
                variant="default"
                className={cn('w-full bg-blue-600 text-white hover:bg-blue-700')}
                disabled
              >
                En attente de reservation
              </Button>
            ) : isCourseNowFromDateString(course.date.fullDate) ? (
              <Button
                variant="default"
                className={cn('w-full bg-blue-600 text-white hover:bg-blue-700')}
              >
                Démarrer le cours
              </Button>
            ) : (
              <Button variant="outline" disabled className="w-full">
                Bientôt
              </Button>
            )}
          </SlotCard>
        ))}
      </div>
      <div className="flex flex-row items-center justify-between gap-4 rounded-2xl p-6">
        <div className="text-2xl font-bold">Séances passées</div>
      </div>
      <div className="no-scrollbar mt-4 flex w-full flex-row gap-6 overflow-x-auto">
        {previousCourses?.map((course) => (
          <SlotCard
            key={course.id}
            date={course.date}
            courseName={course.courseName}
            courseSubject={course.courseSubject}
            courseGrade={course.courseGrade}
            courseLevel={course.courseLevel}
          />
        ))}
      </div>
    </div>
  )
}

import { createFileRoute, Link } from '@tanstack/react-router'
import { Button } from '@viastud/ui/button'
import { grade, GradeEnum } from '@viastud/utils'

export const Route = createFileRoute('/_auth/ressources/')({
  component: Ressources,
})

function Ressources() {
  return (
    <div className="flex w-3/5 flex-col items-stretch gap-4 pt-4">
      <p className="text-2xl font-extrabold text-gray-950">Toutes les ressources</p>
      <div className="flex gap-4">
        {grade.map((grade) => (
          <Link
            to={`/ressources/${grade.toLowerCase()}`}
            className="flex max-w-[325px] grow"
            key={grade}
          >
            <Button
              variant="none"
              className="flex h-56 max-w-80 grow rounded-2xl border border-blue-100 bg-blue-50 text-xl font-bold text-gray-950 hover:border-blue-300 hover:bg-blue-100"
            >
              {GradeEnum[grade]}
            </Button>
          </Link>
        ))}
      </div>
    </div>
  )
}

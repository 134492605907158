import 'dayjs/locale/fr'

import { createFileRoute } from '@tanstack/react-router'
import { Button } from '@viastud/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@viastud/ui/dropdown-menu'
import { useToast } from '@viastud/ui/hooks/use-toast'
import { daysOfWeek, formattedWeekOptions, hours } from '@viastud/ui/lib/availabilities.utils'
import { trpc } from '@viastud/ui/lib/trpc'
import type { AvailabilityWithStatus } from '@viastud/ui/shared/availability-table'
import AvailabilityTable from '@viastud/ui/shared/availability-table'
import { GenericModal } from '@viastud/ui/shared/generic-modal'
import dayjs from 'dayjs'
import { ChevronDown, LoaderCircle } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

export const Route = createFileRoute('/_auth/availabilities/')({
  component: Availabilities,
})

const availabilitySchemaWithStatus = z.object({
  dayOfWeek: z.number().min(0).max(6),
  hour: z.number().min(7).max(20),
  slotId: z.number().nullable(),
  status: z.enum(['UNAVAILABLE', 'AVAILABLE', 'SELECTED']),
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AvailabilitiesSchemaWithStatuses = z.object({
  weekStart: z.string().refine((date) => !Number.isNaN(Date.parse(date)), {
    message: 'Invalid date format',
  }),
  availabilities: z.array(availabilitySchemaWithStatus),
})

type AvailabilityForm = z.infer<typeof AvailabilitiesSchemaWithStatuses>

function Availabilities() {
  const { toast } = useToast()
  const [selectedWeek, setSelectedWeek] = useState(dayjs().startOf('week').add(1, 'day').toDate())
  const [isEditable, setIsEditable] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [pendingWeek, setPendingWeek] = useState<string | null>(null)

  const {
    data: professorAvailabilities,
    isLoading,
    refetch,
  } = trpc.professorAvailabilities.getProfessorAvailabilities.useQuery({
    weekStart: dayjs(selectedWeek).format('YYYY-MM-DD'),
  })

  const saveAvailabilitiesMutation =
    trpc.professorAvailabilities.saveProfessorAvailabilities.useMutation({
      onSuccess: () => {
        void refetch()
        toast({
          title: 'Disponibilités enregistrées avec succès.',
        })
      },
      onError: (error) => {
        toast({
          variant: 'destructive',
          title: `Erreur: ${error.message}`,
        })
      },
    })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = useForm<AvailabilityForm>({
    defaultValues: {
      availabilities: [],
    },
  })

  useEffect(() => {
    if (professorAvailabilities) {
      const mappedAvailabilities: AvailabilityWithStatus[] = professorAvailabilities.map(
        (avail) => ({
          weekStart: dayjs(selectedWeek).format('YYYY-MM-DD'),
          dayOfWeek: avail.dayOfWeek,
          hour: avail.hour,
          slotId: avail.slotId ?? null,
          status: 'SELECTED',
        })
      )

      reset({ availabilities: mappedAvailabilities })
    } else {
      reset({
        availabilities: [],
      })
    }
  }, [professorAvailabilities, reset, selectedWeek])

  const onSubmit = (data: AvailabilityForm) => {
    const availabilitiesToSave: z.infer<typeof availabilitySchemaWithStatus>[] =
      data.availabilities.filter((availabilty) => availabilty.status === 'SELECTED')

    saveAvailabilitiesMutation.mutate({
      weekStart: dayjs(selectedWeek).format('YYYY-MM-DD'),
      availabilities: availabilitiesToSave,
    })
  }

  const toggleEditMode = () => {
    setIsEditable((prev) => !prev)
  }

  const handleWeekChange = (newWeek: string) => {
    if (isDirty) {
      setPendingWeek(newWeek)
      setShowConfirmModal(true)
    } else {
      setSelectedWeek(new Date(newWeek))
    }
  }

  const onCancel = () => {
    setShowConfirmModal(false)
  }

  const onConfirm = () => {
    void handleSubmit(onSubmit)().then(() => {
      setShowConfirmModal(false)
      if (pendingWeek) {
        setSelectedWeek(new Date(pendingWeek))
        setPendingWeek(null)
      }
    })
  }

  return (
    <div className="w-[80%] p-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-8 flex items-start">
          <div className="flex-1">
            <h1 className="mb-4 text-xl font-bold">Indiquez vos disponibilités</h1>
            <p className="mb-2 text-gray-700">
              Sélectionnez plusieurs créneaux selon vos disponibilités. Vous devez prévoir vos cours
              sur une semaine.
            </p>
            <p className="text-gray-700">
              Ceux-ci seront applicables tout le mois. Vous pouvez sélectionner également sur
              quelles semaines appliquer cet emploi du temps.
            </p>
          </div>
          <div className="flex flex-1 justify-end space-x-2">
            <Button type="button" onClick={toggleEditMode}>
              {isEditable ? 'Visualiser' : 'Modifier'}
            </Button>
            {isEditable && (
              <Button type="submit" disabled={saveAvailabilitiesMutation.isPending || !isDirty}>
                {saveAvailabilitiesMutation.isPending ? (
                  <LoaderCircle className="h-4 w-4 animate-spin" />
                ) : (
                  'Valider mes disponibilités'
                )}
              </Button>
            )}
          </div>
        </div>
        <h1 className="mb-4 text-xl font-bold">Semaines applicables</h1>

        <div className="mb-4">
          <label htmlFor="week-select" className="mr-2 text-gray-700">
            Indiquez les semaines disponibles ce mois-ci.
          </label>
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button variant="outline" className="flex grow rounded-3xl">
                <p className="text-sm font-semibold text-blue-800">
                  {`Semaine du ${dayjs(selectedWeek).format('DD/MM/YYYY')}`}
                </p>
                <ChevronDown className="ml-2 h-4 w-4" color="#4C6CFF" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="">
              {formattedWeekOptions.map((week) => (
                <DropdownMenuItem
                  key={`week_${week.value}`}
                  onClick={() => {
                    handleWeekChange(week.value)
                  }}
                >
                  <p className="text-sm text-blue-800">{week.label}</p>
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        {isLoading ? (
          <LoaderCircle className="my-auto h-8 w-8 animate-spin" />
        ) : (
          <Controller
            name="availabilities"
            control={control}
            render={({ field }) => (
              <>
                <AvailabilityTable
                  weekStart={selectedWeek.toISOString()}
                  daysOfWeek={daysOfWeek}
                  hours={hours}
                  defaultStatus="AVAILABLE"
                  availabilities={field.value}
                  isEditable={isEditable}
                  onSelect={(availability) => {
                    const exists = field.value.find(
                      (currentAvailability) =>
                        currentAvailability.dayOfWeek === availability.dayOfWeek &&
                        currentAvailability.hour === availability.hour
                    )
                    if (exists) {
                      field.onChange(
                        field.value.map((currentAvailability) => {
                          if (
                            currentAvailability.dayOfWeek === availability.dayOfWeek &&
                            currentAvailability.hour === availability.hour
                          ) {
                            return {
                              ...currentAvailability,
                              status:
                                currentAvailability.status === 'SELECTED'
                                  ? 'AVAILABLE'
                                  : 'SELECTED',
                            }
                          }
                          return currentAvailability
                        })
                      )
                    } else {
                      field.onChange([
                        ...field.value,
                        {
                          ...availability,
                          status: 'SELECTED',
                        },
                      ])
                    }
                  }}
                />
                {errors.availabilities && (
                  <p className="mt-2 text-sm text-red-500">{errors.availabilities.message}</p>
                )}
              </>
            )}
          />
        )}
      </form>

      <GenericModal
        title="Confirmer le changement de semaine"
        description="Vous avez des modifications non enregistrées. Voulez-vous les enregistrer avant de changer de semaine ?"
        open={showConfirmModal}
        onOpenChange={setShowConfirmModal}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </div>
  )
}

export default Availabilities

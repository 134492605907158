export const subject = ['MATHS', 'PHYSIQUE_CHIMIE'];
export const SubjectEnum = {
    MATHS: 'Mathématiques',
    PHYSIQUE_CHIMIE: 'Physique-chimie',
};
export const SubjectEnumShort = {
    MATHS: 'Maths',
    PHYSIQUE_CHIMIE: 'Physique-chimie',
};
export const SubjectEmoji = {
    MATHS: '🧮',
    PHYSIQUE_CHIMIE: '🧪',
};
export const SubjectIcons = {
    MATHS: '/icons/maths.svg',
    PHYSIQUE_CHIMIE: '/icons/physics-chemistry.svg',
};
export const subjectsOptions = subject.map((subj) => ({
    label: SubjectEnum[subj],
    value: subj,
}));

import { createFileRoute, Link } from '@tanstack/react-router'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@viastud/ui/breadcrumb'
import { Button } from '@viastud/ui/button'
import { trpc } from '@viastud/ui/lib/trpc'
import type { Grade, Subject } from '@viastud/utils'
import {
  caseUnsensitiveEnum,
  grade,
  GradeEnum,
  LevelEnum,
  subject,
  SubjectEnum,
} from '@viastud/utils'
import { z } from 'zod'

export const Route = createFileRoute('/_auth/ressources/$grade/$subject/')({
  component: SubjectRessource,
})

const paramsSchema = z.object({
  grade: caseUnsensitiveEnum(grade),
  subject: caseUnsensitiveEnum(subject),
})

function SubjectRessource() {
  const { grade, subject } = paramsSchema.parse(Route.useParams())
  const sheets =
    trpc.sheet.getMinimalByGradeSubject.useQuery({
      grade: grade.toUpperCase() as Grade,
      subject: subject.toUpperCase() as Subject,
    }).data ?? []

  return (
    <div className="flex w-3/5 flex-col items-stretch gap-4 pt-4">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink to="/ressources">Ressources</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink to={`/ressources/${grade.toLowerCase()}`}>
              {GradeEnum[grade]}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{SubjectEnum[subject]}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <p className="text-2xl font-extrabold text-gray-950">{SubjectEnum[subject]}</p>
      <div className="flex gap-4">
        {sheets.map((sheet) => (
          <Link
            to={`/ressources/${grade.toLowerCase()}/${subject.toLowerCase()}/${sheet.id}`}
            className="flex max-w-[325px] grow"
            key={subject}
          >
            <Button
              variant="none"
              className="flex h-56 max-w-80 grow rounded-2xl border border-blue-100 bg-blue-50 text-xl font-bold text-gray-950 hover:border-blue-300 hover:bg-blue-100"
            >
              <div className="flex flex-col gap-2">
                <p>{sheet.name}</p>
                <p>{LevelEnum[sheet.level]}</p>
              </div>
            </Button>
          </Link>
        ))}
      </div>
    </div>
  )
}

import dayjs from 'dayjs'

export const isCourseNowFromDateString = (courseDate: string | null) => {
  return (
    courseDate &&
    dayjs().isAfter(dayjs(courseDate)) &&
    dayjs().isBefore(dayjs(courseDate).add(1, 'hour'))
  )
}

export const displayFormattedMonth = (dayOfMonth: number) => {
  return dayOfMonth.toString().padStart(2, '0')
}

export const displayFormattedHour = (hour: number) => {
  return `${hour.toString().padStart(2, '0')}h00`
}

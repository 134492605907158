/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as ResetPasswordImport } from './routes/reset-password'
import { Route as ResetLinkSentImport } from './routes/reset-link-sent'
import { Route as RegisterImport } from './routes/register'
import { Route as LoginImport } from './routes/login'
import { Route as ForgottenPasswordImport } from './routes/forgotten-password'
import { Route as AuthImport } from './routes/_auth'
import { Route as AuthIndexImport } from './routes/_auth/index'
import { Route as AuthSettingsImport } from './routes/_auth/settings'
import { Route as AuthRessourcesIndexImport } from './routes/_auth/ressources/index'
import { Route as AuthAvailabilitiesIndexImport } from './routes/_auth/availabilities/index'
import { Route as AuthRessourcesGradeIndexImport } from './routes/_auth/ressources/$grade/index'
import { Route as AuthRessourcesGradeSubjectIndexImport } from './routes/_auth/ressources/$grade/$subject/index'
import { Route as AuthRessourcesGradeSubjectSheetIdIndexImport } from './routes/_auth/ressources/$grade/$subject/$sheetId/index'

// Create Virtual Routes

const MeetingRoomIdLazyImport = createFileRoute('/meeting/$room-id')()

// Create/Update Routes

const ResetPasswordRoute = ResetPasswordImport.update({
  id: '/reset-password',
  path: '/reset-password',
  getParentRoute: () => rootRoute,
} as any)

const ResetLinkSentRoute = ResetLinkSentImport.update({
  id: '/reset-link-sent',
  path: '/reset-link-sent',
  getParentRoute: () => rootRoute,
} as any)

const RegisterRoute = RegisterImport.update({
  id: '/register',
  path: '/register',
  getParentRoute: () => rootRoute,
} as any)

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const ForgottenPasswordRoute = ForgottenPasswordImport.update({
  id: '/forgotten-password',
  path: '/forgotten-password',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const AuthIndexRoute = AuthIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthRoute,
} as any)

const MeetingRoomIdLazyRoute = MeetingRoomIdLazyImport.update({
  id: '/meeting/$room-id',
  path: '/meeting/$room-id',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/meeting/$room-id.lazy').then((d) => d.Route),
)

const AuthSettingsRoute = AuthSettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => AuthRoute,
} as any)

const AuthRessourcesIndexRoute = AuthRessourcesIndexImport.update({
  id: '/ressources/',
  path: '/ressources/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthAvailabilitiesIndexRoute = AuthAvailabilitiesIndexImport.update({
  id: '/availabilities/',
  path: '/availabilities/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthRessourcesGradeIndexRoute = AuthRessourcesGradeIndexImport.update({
  id: '/ressources/$grade/',
  path: '/ressources/$grade/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthRessourcesGradeSubjectIndexRoute =
  AuthRessourcesGradeSubjectIndexImport.update({
    id: '/ressources/$grade/$subject/',
    path: '/ressources/$grade/$subject/',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthRessourcesGradeSubjectSheetIdIndexRoute =
  AuthRessourcesGradeSubjectSheetIdIndexImport.update({
    id: '/ressources/$grade/$subject/$sheetId/',
    path: '/ressources/$grade/$subject/$sheetId/',
    getParentRoute: () => AuthRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/forgotten-password': {
      id: '/forgotten-password'
      path: '/forgotten-password'
      fullPath: '/forgotten-password'
      preLoaderRoute: typeof ForgottenPasswordImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/register': {
      id: '/register'
      path: '/register'
      fullPath: '/register'
      preLoaderRoute: typeof RegisterImport
      parentRoute: typeof rootRoute
    }
    '/reset-link-sent': {
      id: '/reset-link-sent'
      path: '/reset-link-sent'
      fullPath: '/reset-link-sent'
      preLoaderRoute: typeof ResetLinkSentImport
      parentRoute: typeof rootRoute
    }
    '/reset-password': {
      id: '/reset-password'
      path: '/reset-password'
      fullPath: '/reset-password'
      preLoaderRoute: typeof ResetPasswordImport
      parentRoute: typeof rootRoute
    }
    '/_auth/settings': {
      id: '/_auth/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof AuthSettingsImport
      parentRoute: typeof AuthImport
    }
    '/meeting/$room-id': {
      id: '/meeting/$room-id'
      path: '/meeting/$room-id'
      fullPath: '/meeting/$room-id'
      preLoaderRoute: typeof MeetingRoomIdLazyImport
      parentRoute: typeof rootRoute
    }
    '/_auth/': {
      id: '/_auth/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/availabilities/': {
      id: '/_auth/availabilities/'
      path: '/availabilities'
      fullPath: '/availabilities'
      preLoaderRoute: typeof AuthAvailabilitiesIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/ressources/': {
      id: '/_auth/ressources/'
      path: '/ressources'
      fullPath: '/ressources'
      preLoaderRoute: typeof AuthRessourcesIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/ressources/$grade/': {
      id: '/_auth/ressources/$grade/'
      path: '/ressources/$grade'
      fullPath: '/ressources/$grade'
      preLoaderRoute: typeof AuthRessourcesGradeIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/ressources/$grade/$subject/': {
      id: '/_auth/ressources/$grade/$subject/'
      path: '/ressources/$grade/$subject'
      fullPath: '/ressources/$grade/$subject'
      preLoaderRoute: typeof AuthRessourcesGradeSubjectIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/ressources/$grade/$subject/$sheetId/': {
      id: '/_auth/ressources/$grade/$subject/$sheetId/'
      path: '/ressources/$grade/$subject/$sheetId'
      fullPath: '/ressources/$grade/$subject/$sheetId'
      preLoaderRoute: typeof AuthRessourcesGradeSubjectSheetIdIndexImport
      parentRoute: typeof AuthImport
    }
  }
}

// Create and export the route tree

interface AuthRouteChildren {
  AuthSettingsRoute: typeof AuthSettingsRoute
  AuthIndexRoute: typeof AuthIndexRoute
  AuthAvailabilitiesIndexRoute: typeof AuthAvailabilitiesIndexRoute
  AuthRessourcesIndexRoute: typeof AuthRessourcesIndexRoute
  AuthRessourcesGradeIndexRoute: typeof AuthRessourcesGradeIndexRoute
  AuthRessourcesGradeSubjectIndexRoute: typeof AuthRessourcesGradeSubjectIndexRoute
  AuthRessourcesGradeSubjectSheetIdIndexRoute: typeof AuthRessourcesGradeSubjectSheetIdIndexRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthSettingsRoute: AuthSettingsRoute,
  AuthIndexRoute: AuthIndexRoute,
  AuthAvailabilitiesIndexRoute: AuthAvailabilitiesIndexRoute,
  AuthRessourcesIndexRoute: AuthRessourcesIndexRoute,
  AuthRessourcesGradeIndexRoute: AuthRessourcesGradeIndexRoute,
  AuthRessourcesGradeSubjectIndexRoute: AuthRessourcesGradeSubjectIndexRoute,
  AuthRessourcesGradeSubjectSheetIdIndexRoute:
    AuthRessourcesGradeSubjectSheetIdIndexRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof AuthRouteWithChildren
  '/forgotten-password': typeof ForgottenPasswordRoute
  '/login': typeof LoginRoute
  '/register': typeof RegisterRoute
  '/reset-link-sent': typeof ResetLinkSentRoute
  '/reset-password': typeof ResetPasswordRoute
  '/settings': typeof AuthSettingsRoute
  '/meeting/$room-id': typeof MeetingRoomIdLazyRoute
  '/': typeof AuthIndexRoute
  '/availabilities': typeof AuthAvailabilitiesIndexRoute
  '/ressources': typeof AuthRessourcesIndexRoute
  '/ressources/$grade': typeof AuthRessourcesGradeIndexRoute
  '/ressources/$grade/$subject': typeof AuthRessourcesGradeSubjectIndexRoute
  '/ressources/$grade/$subject/$sheetId': typeof AuthRessourcesGradeSubjectSheetIdIndexRoute
}

export interface FileRoutesByTo {
  '/forgotten-password': typeof ForgottenPasswordRoute
  '/login': typeof LoginRoute
  '/register': typeof RegisterRoute
  '/reset-link-sent': typeof ResetLinkSentRoute
  '/reset-password': typeof ResetPasswordRoute
  '/settings': typeof AuthSettingsRoute
  '/meeting/$room-id': typeof MeetingRoomIdLazyRoute
  '/': typeof AuthIndexRoute
  '/availabilities': typeof AuthAvailabilitiesIndexRoute
  '/ressources': typeof AuthRessourcesIndexRoute
  '/ressources/$grade': typeof AuthRessourcesGradeIndexRoute
  '/ressources/$grade/$subject': typeof AuthRessourcesGradeSubjectIndexRoute
  '/ressources/$grade/$subject/$sheetId': typeof AuthRessourcesGradeSubjectSheetIdIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_auth': typeof AuthRouteWithChildren
  '/forgotten-password': typeof ForgottenPasswordRoute
  '/login': typeof LoginRoute
  '/register': typeof RegisterRoute
  '/reset-link-sent': typeof ResetLinkSentRoute
  '/reset-password': typeof ResetPasswordRoute
  '/_auth/settings': typeof AuthSettingsRoute
  '/meeting/$room-id': typeof MeetingRoomIdLazyRoute
  '/_auth/': typeof AuthIndexRoute
  '/_auth/availabilities/': typeof AuthAvailabilitiesIndexRoute
  '/_auth/ressources/': typeof AuthRessourcesIndexRoute
  '/_auth/ressources/$grade/': typeof AuthRessourcesGradeIndexRoute
  '/_auth/ressources/$grade/$subject/': typeof AuthRessourcesGradeSubjectIndexRoute
  '/_auth/ressources/$grade/$subject/$sheetId/': typeof AuthRessourcesGradeSubjectSheetIdIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/forgotten-password'
    | '/login'
    | '/register'
    | '/reset-link-sent'
    | '/reset-password'
    | '/settings'
    | '/meeting/$room-id'
    | '/'
    | '/availabilities'
    | '/ressources'
    | '/ressources/$grade'
    | '/ressources/$grade/$subject'
    | '/ressources/$grade/$subject/$sheetId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/forgotten-password'
    | '/login'
    | '/register'
    | '/reset-link-sent'
    | '/reset-password'
    | '/settings'
    | '/meeting/$room-id'
    | '/'
    | '/availabilities'
    | '/ressources'
    | '/ressources/$grade'
    | '/ressources/$grade/$subject'
    | '/ressources/$grade/$subject/$sheetId'
  id:
    | '__root__'
    | '/_auth'
    | '/forgotten-password'
    | '/login'
    | '/register'
    | '/reset-link-sent'
    | '/reset-password'
    | '/_auth/settings'
    | '/meeting/$room-id'
    | '/_auth/'
    | '/_auth/availabilities/'
    | '/_auth/ressources/'
    | '/_auth/ressources/$grade/'
    | '/_auth/ressources/$grade/$subject/'
    | '/_auth/ressources/$grade/$subject/$sheetId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthRoute: typeof AuthRouteWithChildren
  ForgottenPasswordRoute: typeof ForgottenPasswordRoute
  LoginRoute: typeof LoginRoute
  RegisterRoute: typeof RegisterRoute
  ResetLinkSentRoute: typeof ResetLinkSentRoute
  ResetPasswordRoute: typeof ResetPasswordRoute
  MeetingRoomIdLazyRoute: typeof MeetingRoomIdLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  AuthRoute: AuthRouteWithChildren,
  ForgottenPasswordRoute: ForgottenPasswordRoute,
  LoginRoute: LoginRoute,
  RegisterRoute: RegisterRoute,
  ResetLinkSentRoute: ResetLinkSentRoute,
  ResetPasswordRoute: ResetPasswordRoute,
  MeetingRoomIdLazyRoute: MeetingRoomIdLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth",
        "/forgotten-password",
        "/login",
        "/register",
        "/reset-link-sent",
        "/reset-password",
        "/meeting/$room-id"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/settings",
        "/_auth/",
        "/_auth/availabilities/",
        "/_auth/ressources/",
        "/_auth/ressources/$grade/",
        "/_auth/ressources/$grade/$subject/",
        "/_auth/ressources/$grade/$subject/$sheetId/"
      ]
    },
    "/forgotten-password": {
      "filePath": "forgotten-password.tsx"
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/register": {
      "filePath": "register.tsx"
    },
    "/reset-link-sent": {
      "filePath": "reset-link-sent.tsx"
    },
    "/reset-password": {
      "filePath": "reset-password.tsx"
    },
    "/_auth/settings": {
      "filePath": "_auth/settings.tsx",
      "parent": "/_auth"
    },
    "/meeting/$room-id": {
      "filePath": "meeting/$room-id.lazy.tsx"
    },
    "/_auth/": {
      "filePath": "_auth/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/availabilities/": {
      "filePath": "_auth/availabilities/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/ressources/": {
      "filePath": "_auth/ressources/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/ressources/$grade/": {
      "filePath": "_auth/ressources/$grade/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/ressources/$grade/$subject/": {
      "filePath": "_auth/ressources/$grade/$subject/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/ressources/$grade/$subject/$sheetId/": {
      "filePath": "_auth/ressources/$grade/$subject/$sheetId/index.tsx",
      "parent": "/_auth"
    }
  }
}
ROUTE_MANIFEST_END */

import { useBlocker } from '@tanstack/react-router'
import { Button } from '@viastud/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel } from '@viastud/ui/form'
import { Input } from '@viastud/ui/input'
import type { EditPasswordMutation } from '@viastud/ui/types/edit-password'
import type { EditProfileSchema } from 'node_modules/@viastud/utils/src/validators/common'
import type { Dispatch, SetStateAction} from 'react';
import type { UseFormReturn } from 'react-hook-form'

import { ConfirmLeaveModal } from '#components/shared/confirm-leave-modal'
import { EditPasswordModal } from '#components/shared/edit-password-modal'

type EditProfileProps =
  | {
      editPassword?: false
      editProfileForm: UseFormReturn<EditProfileSchema>
      onSubmit: (data: EditProfileSchema) => void
      isDirty: boolean
      setIsDirty: Dispatch<SetStateAction<boolean>>
    }
  | {
      editPassword: true
      editProfileForm: UseFormReturn<EditProfileSchema>
      onSubmit: (data: EditProfileSchema) => void
      updatePassword: EditPasswordMutation
      isDirty: boolean
      setIsDirty: Dispatch<SetStateAction<boolean>>
    }

export function EditProfile({ editProfileForm, isDirty, setIsDirty, onSubmit, ...props }: EditProfileProps) {

  const { proceed, reset, status } = useBlocker({
    condition: isDirty,
  })

  return (
    <>
      <ConfirmLeaveModal
        open={status === 'blocked'}
        title='Vous avez des modifications de profil non sauvgardées. Êtes-vous sûr de vouloir quitter ?'
        cancel={reset}
        confirm={proceed}
      />
      <Form {...editProfileForm}>
        <form className="flex flex-col gap-4" onSubmit={editProfileForm.handleSubmit(onSubmit)} onChange={() => { setIsDirty(true) } }>
          <div className="flex items-center justify-between">
            <p className="text-2xl font-extrabold text-gray-950">Général</p>
            <Button className="border-blue-300"
              type="submit"
              disabled={!isDirty}
              variant={isDirty ? 'default' : 'secondary'}
            >
              Enregistrer les modifications
            </Button>
          </div>
          <div className="flex gap-6">
            <div className="flex grow flex-col gap-6">
              <FormField
                control={editProfileForm.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem className="flex w-full flex-col">
                    <FormLabel className="text-sm font-medium text-gray-700">Nom</FormLabel>
                    <FormControl>
                      <Input className="shadow-custom w-full" {...field} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={editProfileForm.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem className="flex w-full flex-col">
                    <FormLabel className="text-sm font-medium text-gray-700">Prénom</FormLabel>
                    <FormControl>
                      <Input className="shadow-custom w-full" {...field} />
                    </FormControl>
                  </FormItem>
                )}
              />
              {props.editPassword && (
                <div className="flex flex-col gap-2">
                  <p className="text-sm font-medium text-gray-700">Mot de passe*</p>
                  <Input disabled className="shadow-custom w-full bg-blue-50" value="********" />
                  <div className="flex mt-6">
                    <EditPasswordModal updatePassword={props.updatePassword} />
                  </div>
                </div>
              )}
            </div>
            <div className="flex grow flex-col gap-6">
              <FormField
                control={editProfileForm.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="flex w-full flex-col">
                    <FormLabel className="text-sm font-medium text-gray-700">
                      Adresse e-mail
                    </FormLabel>
                    <FormControl>
                      <Input className="shadow-custom w-full" {...field} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={editProfileForm.control}
                name="phoneNumber"
                render={({ field }) => (
                  <FormItem className="flex w-full flex-col">
                    <FormLabel className="text-sm font-medium text-gray-700">
                      Numéro de téléphone
                    </FormLabel>
                    <FormControl>
                      <Input className="shadow-custom w-full" {...field} />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </div>
        </form>
      </Form>
    </>
  )
}

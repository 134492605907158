import { z } from 'zod';
export const editExerciceSchema = z.object({
    id: z.number(),
    name: z
        .string({
        required_error: 'Le nom est requis',
    })
        .min(1),
    moduleId: z.string(),
});

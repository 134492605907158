import { z } from 'zod';
import { level } from '#enums/level';
export const editSheetSchema = z.object({
    id: z.number(),
    name: z
        .string({
        required_error: 'Le nom est requis',
    })
        .min(1),
    description: z.string(),
    moduleId: z.string(),
    level: z.enum(level, {
        required_error: 'Le niveau est requis',
    }),
    content: z.string().min(1),
});

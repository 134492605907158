import { z } from 'zod';
import { grade } from '#enums/class';
import { subject } from '#enums/subject';
export const editUserSchema = z.object({
    id: z.string().uuid(),
    lastName: z
        .string({
        required_error: 'Le nom est requis',
    })
        .min(1),
    firstName: z
        .string({
        required_error: 'Le prénom est requis',
    })
        .min(1),
    email: z
        .string({
        required_error: 'L&apos;adresse e-mail est requise',
    })
        .email()
        .min(1),
    phoneNumber: z
        .string({
        required_error: 'Le numéro de téléphone est requis',
    })
        .min(1),
    interestedIn: z.array(z.enum(subject)).optional(),
    grade: z.enum(grade).nullable().optional(),
    modules: z
        .array(z.object({
        id: z.number(),
        name: z.string(),
        grade: z.enum(grade),
        subject: z.enum(subject),
        doneModule: z.boolean(),
        doingModule: z.boolean(),
    }))
        .optional(),
});
